/* General styling */
body {
  font-family: 'Arial', sans-serif;
  margin: 0;
  padding: 0;
  background-color: #000;
  color: #fff;
}

/* Work Gallery Container */
.work-gallery {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 15px;
  background-color: #222;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(255, 255, 255, 0.1);
  align-items: center;
}

/* Category Navigation */
.categories-nav {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 15px;
  background-color: #444;
  padding: 10px 0;
  border-radius: 30px;
  width: 100%;
}

.category-btn {
  padding: 10px 25px;
  font-size: 15px;
  background-color: transparent;
  color: #fff;
  border: 2px solid #fff;
  border-radius: 30px;
  cursor: pointer;
  transition: all 0.3s ease;
  margin: 0 6px;
}

.category-btn.active {
  background-color: #fff;
  color: #000;
}

.category-btn:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 10px rgba(255, 255, 255, 0.2);
}

/* Subcategory Navigation */
.subcategories-nav {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 8px;
  margin-top: 10px;
  background-color: #444;
  padding: 8px 0;
  border-radius: 30px;
  width: 100%;
}

.subcategory-btn {
  padding: 7px 18px;
  font-size: 13px;
  background-color: transparent;
  color: #fff;
  border: 2px solid #fff;
  border-radius: 30px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.subcategory-btn.active {
  background-color: #fff;
  color: #000;
}

.subcategory-btn:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 10px rgba(255, 255, 255, 0.2);
}

/* Media Display */
.media-display {
  margin-top: 10px;
}

/* Media Grid - Display in Rows */
.media-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* 4 items per row on desktop */
  gap: 10px; /* Reduced gap */
  width: 100%;
  justify-content: center;
  align-items: center;
}

/* Media Card */
.media-card {
  background-color: #333;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(255, 255, 255, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 6px; /* Reduced padding */
  width: 100%;
}

.media-card:hover {
  transform: translateY(-3px);
  box-shadow: 0 6px 12px rgba(255, 255, 255, 0.2);
}

/* Image & Video Styling */
.media-img,
.media-video {
  width: 100%;
  height: auto;
  object-fit: contain;
  border-radius: 8px;
}

/* Portrait images should fit properly */
.media-img.portrait {
  max-width: 100%;
  max-height: 280px;
}

/* Description Section */
.description {
  padding: 8px;
  text-align: center;
  background-color: #222;
}

.description h4 {
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  margin: 0;
}

.description p {
  font-size: 12px;
  color: #ddd;
  line-height: 1.4;
}

/* Loading Text */
.loading-text {
  text-align: center;
  font-size: 16px;
  color: #fff;
}

/* Error Message */
.error-message {
  text-align: center;
  color: red;
  font-weight: bold;
  padding: 10px;
}

/* Responsive Design */
@media (max-width: 1024px) {
  /* 3 items per row on tablets */
  .media-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 768px) {
  /* 2 items per row on smaller tablets */
  .media-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 480px) {
  /* 2 items per row on phones */
  .media-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}
