/* Overall Review Box */
.reveiw-box {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(135deg, #000, #222);
  color: white;
  font-family: 'Poppins', sans-serif;
  padding: 20px;
}

/* Review Main Container */
.reveiw-main {
  background: #111;
  padding: 30px;
  border-radius: 12px;
  box-shadow: 0 5px 15px rgba(255, 255, 255, 0.2);
  text-align: center;
  width: 50%;
  max-width: 500px;
  animation: fadeIn 0.6s ease-in-out;
}

/* Title Styling */
.reveiw-main h6 {
  font-size: 20px;
  margin-bottom: 15px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  background: linear-gradient(90deg, #fff, #aaa);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* Input Fields */
.reveiw-name,
.reveiw-input {
  width: 100%;
  padding: 12px;
  margin: 10px 0;
  border: 2px solid white;
  background: transparent;
  color: white;
  border-radius: 8px;
  font-size: 14px;
  transition: all 0.3s ease-in-out;
  text-align: center;
}

.reveiw-name {
  font-weight: bold;
}

.reveiw-input::placeholder {
  color: #ccc;
}

.reveiw-name:focus,
.reveiw-input:focus {
  border-color: #1dbf73;
  background: rgba(255, 255, 255, 0.1);
  outline: none;
}

/* Submit Button */
.reveiw-main input[type="submit"] {
  width: 100%;
  padding: 12px;
  background: white;
  color: black;
  font-size: 16px;
  font-weight: bold;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  margin-top: 10px;
  transition: all 0.3s ease-in-out;
}

.reveiw-main input[type="submit"]:hover {
  background: #ccc;
  transform: scale(1.05);
}

/* Review Display Box */
.reveiw-div {
  background: #222;
  padding: 15px;
  border-radius: 8px;
  margin-top: 15px;
  animation: slideUp 0.5s ease-in-out;
  box-shadow: 0 3px 10px rgba(255, 255, 255, 0.2);
}

.reveiw-h6 {
  font-size: 18px;
  font-weight: 600;
  color: #1dbf73;
}

.reveiw-content {
  font-size: 14px;
  color: #ccc;
  margin-top: 5px;
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* ✨ Responsive Design ✨ */
@media screen and (max-width: 768px) {
  .reveiw-main {
    width: 80%;
    padding: 25px;
  }

  .reveiw-main h6 {
    font-size: 18px;
  }

  .reveiw-name,
  .reveiw-input {
    font-size: 13px;
    padding: 10px;
  }

  .reveiw-main input[type="submit"] {
    font-size: 14px;
  }

  .reveiw-div {
    padding: 12px;
  }

  .reveiw-h6 {
    font-size: 16px;
  }

  .reveiw-content {
    font-size: 13px;
  }
}

@media screen and (max-width: 480px) {
  .reveiw-main {
    width: 90%;
    padding: 20px;
  }

  .reveiw-main h6 {
    font-size: 16px;
  }

  .reveiw-name,
  .reveiw-input {
    font-size: 12px;
    padding: 8px;
  }

  .reveiw-main input[type="submit"] {
    font-size: 13px;
  }

  .reveiw-div {
    padding: 10px;
  }

  .reveiw-h6 {
    font-size: 14px;
  }

  .reveiw-content {
    font-size: 12px;
  }
}
