/* Review List Container */
.review-list-container {
    background: linear-gradient(135deg, #000000, #1a1a1a); /* Dark gradient */
    padding: 30px;
    border-radius: 15px;
    box-shadow: 0px 4px 20px rgba(255, 255, 255, 0.3);
    max-width: 750px;
    width: 90%;
    margin: 50px auto;
    text-align: center;
    transition: all 0.4s ease-in-out;
    border: 2px solid rgba(255, 255, 255, 0.1);
    position: relative;
    overflow: hidden;
}

/* Floating Glow Effect */
.review-list-container::before {
    content: "";
    position: absolute;
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
    background: radial-gradient(circle, rgba(255, 255, 255, 0.1) 10%, transparent 70%);
    animation: glow-move 6s linear infinite;
}

/* Glowing animation */
@keyframes glow-move {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

/* Title */
.review-list-title {
    font-size: 28px;
    font-weight: bold;
    color: #ffffff;
    text-transform: uppercase;
    margin-bottom: 20px;
    letter-spacing: 2px;
    text-shadow: 0px 0px 10px rgba(255, 255, 255, 0.5);
}

/* Review Item */
.review-item {
    background: rgba(255, 255, 255, 0.05); /* Transparent card effect */
    padding: 20px;
    margin: 15px 0;
    border-radius: 12px;
    text-align: left;
    box-shadow: 0px 4px 15px rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(10px); /* Frosted glass effect */
    transition: transform 0.4s ease-in-out, box-shadow 0.4s ease-in-out;
    border: 1px solid rgba(255, 255, 255, 0.1);
}

/* Hover effect */
.review-item:hover {
    transform: scale(1.05);
    box-shadow: 0px 4px 20px rgba(255, 255, 255, 0.5);
}

/* Username */
.review-username {
    font-size: 22px;
    font-weight: bold;
    color: #ffffff;
    margin-bottom: 5px;
    text-transform: capitalize;
    text-shadow: 0px 0px 8px rgba(255, 255, 255, 0.5);
}

/* Review Text */
.review-text {
    font-size: 18px;
    color: #e0e0e0; /* Light gray */
    line-height: 1.8;
    margin-bottom: 10px;
    letter-spacing: 0.5px;
}

/* Star Rating */
.review-rating {
    font-size: 22px;
    color: gold;
    font-weight: bold;
    letter-spacing: 3px;
    text-shadow: 0px 0px 5px rgba(255, 215, 0, 0.8);
    display: flex;
    align-items: center;
}

/* Responsive Design */
@media (max-width: 1024px) {
    .review-list-container {
        width: 85%;
    }

    .review-list-title {
        font-size: 24px;
    }

    .review-item {
        padding: 18px;
    }

    .review-text {
        font-size: 17px;
    }

    .review-rating {
        font-size: 20px;
    }
}

@media (max-width: 768px) {
    .review-list-container {
        width: 95%;
        padding: 25px;
    }

    .review-list-title {
        font-size: 22px;
    }

    .review-item {
        padding: 16px;
    }

    .review-text {
        font-size: 16px;
    }

    .review-rating {
        font-size: 18px;
    }
}

@media (max-width: 480px) {
    .review-list-container {
        width: 98%;
        padding: 20px;
    }

    .review-list-title {
        font-size: 20px;
    }

    .review-item {
        padding: 14px;
    }

    .review-text {
        font-size: 15px;
    }

    .review-rating {
        font-size: 16px;
    }
}
