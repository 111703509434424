/* Services Page Styles */
.services-container {
  background-color: #000;
  color: #fff;
  padding: 50px 5%;
  text-align: center;
  font-family: 'Arial', sans-serif;
}

.services-heading {
  font-size: 2.5rem;
  margin-bottom: 10px;
  text-transform: uppercase;
  letter-spacing: 2px;
  animation: fadeInDown 1s ease-in-out;
}

.services-subtext {
  font-size: 1.2rem;
  margin-bottom: 40px;
  font-style: italic;
  opacity: 0.8;
  animation: fadeIn 2s ease-in-out;
}

.service-section {
  background: rgba(255, 255, 255, 0.1);
  padding: 30px;
  border-radius: 10px;
  margin-bottom: 40px;
  transition: transform 0.3s ease-in-out;
}

.service-section:hover {
  transform: scale(1.02);
}

.service-title a {
  font-size: 1.8rem;
  color: #fff;
  text-decoration: none;
  transition: color 0.3s;
}

.service-title a:hover {
  color: #ccc;
}

.service-description {
  font-size: 1rem;
  margin: 15px 0;
}

.service-images {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin: 20px 0;
}

.service-images img,
.service-images video {
  width: 20%; /* 1/5th of the screen width */
  height: auto;
  border-radius: 10px;
  transition: transform 0.3s ease-in-out;
}

.service-images img:hover,
.service-images video:hover {
  transform: scale(1.1);
}
.service-video {
  width: 20%; /* 1/5th of the screen */
  height: auto;
  border-radius: 10px; /* Soft rounded corners */
  object-fit: cover;
  filter: grayscale(100%); /* Black & white effect */
  transition: transform 0.3s ease-in-out, filter 0.3s ease-in-out;
}

.service-video:hover {
  transform: scale(1.05);
  filter: grayscale(0%); /* Color back on hover */
}

.toggle-button {
  background: none;
  color: white;
  border: 1px solid white;
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  margin-top: 15px;
  transition: background 0.3s ease-in-out;
}

.toggle-button:hover {
  background: white;
  color: black;
}

.more-details {
  background: rgba(255, 255, 255, 0.2);
  padding: 15px;
  border-radius: 10px;
  margin-top: 20px;
  animation: fadeIn 0.5s ease-in-out;
}

.includes-title {
  font-size: 1.3rem;
  margin-bottom: 10px;
}

.includes-list {
  list-style: none;
  padding: 0;
}

.includes-list li {
  margin: 5px 0;
  font-size: 1rem;
}

.includes-list li a {
  color: white;
  text-decoration: none;
  transition: color 0.3s;
}

.includes-list li a:hover {
  color: #ccc;
}

.bhire-container {
  text-align: center;
  margin-top: 30px;
}

.bhire {
  display: inline-block;
  background: white;
  color: black;
  padding: 15px 30px;
  font-size: 1.2rem;
  text-decoration: none;
  border-radius: 5px;
  transition: background 0.3s, color 0.3s;
}

.bhire:hover {
  background: black;
  color: white;
  border: 1px solid white;
}

/* Animations */
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes fadeInDown {
  from { opacity: 0; transform: translateY(-20px); }
  to { opacity: 1; transform: translateY(0); }
}