/* Review Form Container */
.review-container {
    background: #111;
    padding: 25px;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(255, 255, 255, 0.2);
    max-width: 700px;
    width: 90%;
    margin: 50px auto;
    text-align: center;
    transition: all 0.3s ease-in-out;
}

/* Title */
.review-title {
    font-size: 24px;
    font-weight: bold;
    color: #fff;
    text-transform: uppercase;
    margin-bottom: 15px;
    letter-spacing: 1.5px;
}

/* Review Textarea */
.review-form textarea {
    width: 100%;
    height: 120px;
    padding: 12px;
    font-size: 16px;
    color: #fff;
    background: #222;
    border: 1px solid #444;
    border-radius: 8px;
    resize: none;
    transition: all 0.3s ease;
}

.review-form textarea:focus {
    border-color: #fff;
    box-shadow: 0px 0px 8px rgba(255, 255, 255, 0.3);
}

/* Star Rating Select */
.star-rating {
    display: block;
    width: 100%;
    padding: 10px;
    font-size: 18px;
    color: gold;
    background: #222;
    border: 1px solid #444;
    border-radius: 8px;
    margin: 10px 0;
    transition: all 0.3s ease;
}

.star-rating:hover {
    cursor: pointer;
    border-color: gold;
}

/* Submit Button */
.submit-btn {
    width: 100%;
    padding: 12px;
    font-size: 18px;
    font-weight: bold;
    color: #111;
    background: white;
    border: none;
    border-radius: 8px;
    transition: all 0.3s ease-in-out;
}

.submit-btn:hover {
    background: gold;
    color: black;
    transform: scale(1.05);
}

/* Responsive */
@media (max-width: 768px) {
    .review-container {
        width: 95%;
        padding: 20px;
    }

    .review-title {
        font-size: 20px;
    }

    .review-form textarea {
        height: 100px;
    }

    .star-rating {
        font-size: 16px;
    }
}
