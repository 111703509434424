/* General Styles */
.login-page-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: #000; /* Black background for contrast */
  padding: 1rem;
}

.login-container {
  width: 100%;
  max-width: 450px; /* Wider on desktops */
}

/* Login Box */
.login-box {
  background: #1a1a1a;
  padding: 2.5rem;
  border-radius: 12px;
  box-shadow: 0px 0px 15px rgba(255, 255, 255, 0.1);
  text-align: center;
  transition: transform 0.3s ease-in-out;
  width: 100%;
  max-width: 450px; /* Makes it wider on large screens */
}

.login-box:hover {
  transform: scale(1.02);
}

.login-title {
  font-size: 2rem;
  color: #fff;
  margin-bottom: 1.2rem;
}

/* Form Styling */
.login-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.login-input-group {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.login-input-group label {
  font-size: 1rem;
  color: #ccc;
  margin-bottom: 0.3rem;
}

.login-input-group input {
  padding: 0.8rem;
  border: 1px solid #444;
  border-radius: 6px;
  background: #222;
  color: #fff;
  font-size: 1rem;
  transition: 0.3s;
}

.login-input-group input:focus {
  border-color: #fff;
  outline: none;
}

/* Login & Register Button */
.login-btn {
  padding: 0.8rem;
  font-size: 1.2rem;
  background: linear-gradient(45deg, #fff, #777);
  color: #000;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-weight: bold;
  transition: all 0.3s ease-in-out;
}

.login-btn:hover {
  background: linear-gradient(45deg, #777, #fff);
  transform: scale(1.05);
}

/* Social Login Button */
.social-login-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.7rem;
  background: #444;
  color: #fff;
  padding: 0.8rem;
  font-size: 1.1rem;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.social-login-btn:hover {
  background: #666;
  transform: scale(1.05);
}

.social-login-btn svg {
  fill: #fff; /* Ensures the Google icon is white */
  width: 1.5rem;
  height: 1.5rem;
  transition: all 0.3s ease-in-out;
}

.social-login-btn:hover svg {
  transform: rotate(10deg);
}

/* Register/Login Toggle */
.login-text {
  margin-top: 1rem;
  color: #ccc;
}

.login-link {
  color: #fff;
  font-weight: bold;
  transition: color 0.3s ease-in-out;
}

.login-link:hover {
  color: #ddd;
}

/* Responsive Design */
@media (max-width: 768px) {
  .login-container {
      max-width: 95%;
  }

  .login-box {
      max-width: 100%;
      padding: 2rem;
  }

  .login-title {
      font-size: 1.6rem;
  }

  .login-btn, .social-login-btn {
      font-size: 1rem;
      padding: 0.6rem;
  }
}

@media (max-width: 480px) {
  .login-box {
      padding: 1.8rem;
  }

  .login-title {
      font-size: 1.5rem;
  }

  .login-btn, .social-login-btn {
      font-size: 0.9rem;
      padding: 0.5rem;
  }
}
