/* General Styles */
.connectus-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 40px 20px;
    background: #000;
    color: #fff;
    text-align: center;
  }
  
  /* Header */
  .connectus-header {
    margin-bottom: 40px;
  }
  
  .connectus-title {
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 10px;
    text-transform: uppercase;
    letter-spacing: 1px;
  }
  
  .connectus-subtitle {
    font-size: 16px;
    line-height: 1.6;
    max-width: 800px;
    margin: 0 auto;
  }
  
  /* Social Media & Contact */
  .connectus-content {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    gap: 50px;
    margin-bottom: 50px;
  }
  
  /* Sections */
  .connectus-section {
    flex: 1;
    min-width: 280px;
    max-width: 350px;
    text-align: center;
  }
  
  /* Headings */
  .connectus-heading {
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 20px;
    text-decoration: underline;
  }
  
  /* Social Icons */
  .connectus-socials,
  .connectus-contact {
    list-style: none;
    padding: 0;
    display: flex;
    justify-content: center;
    gap: 20px;
  }
  
  .connectus-socials li,
  .connectus-contact li {
    font-size: 24px;
    color: white;  /* Icons in white */
    transition: transform 0.3s ease;
  }
  
  .connectus-socials li a,
  .connectus-contact li a {
    color: white; /* Icons in white */
  }
  
  .connectus-socials li:hover,
  .connectus-contact li:hover {
    transform: scale(1.2);
  }
  
  /* Location Section */
  .connectus-location {
    text-align: center;
    max-width: 700px;
    margin: 0 auto;
  }
  
  .connectus-map {
    width: 100%;
    max-width: 400px; /* Reduced size */
    border-radius: 10px;
    margin: 15px 0;
  }
  
  .connectus-address {
    font-size: 16px;
    font-style: italic;
    max-width: 600px;
    margin: 10px auto 0;
  }
  
  /* Spacing Between Sections */
  .connectus-section + .connectus-section {
    margin-top: 50px; /* Increased gap */
  }
  
  /* Responsive */
  @media (max-width: 768px) {
    .connectus-content {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      gap: 40px;
    }
  
    .connectus-socials,
    .connectus-contact {
      flex-wrap: wrap;
      justify-content: center;
    }
  
    .connectus-map {
      max-width: 300px; /* Smaller map for mobile */
    }
  }
  