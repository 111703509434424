/* General Styles */
body {
    margin: 0;
    font-family: 'Poppins', sans-serif;
    background: #000;
    color: #fff;
  }
  
  /* Video Background Section */
  .video-container {
    position: relative;
    width: 100%;
    height: 80vh;
    overflow: hidden;
  }
  
  .background-video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    filter: grayscale(100%);
  }
  
  .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    top: 0;
    left: 0;
  }
  
 /* Hero Section */
.hero-text {
    position: relative;
    z-index: 1;
    text-align: center;
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
  }
  
  .type-animation {
    font-size:1.5rem;
    font-weight: bold;
    text-transform: uppercase;
    display: inline-block; /* Ensures inline layout */
    white-space: nowrap; /* Prevents text from breaking into new lines */
  }
  
  .sub-text {
    font-size: 1.2rem;
    margin-top: 10px;
    color: #bbb;
  }
  
  .cta-button {
    display: inline-block;
    margin-top: 20px;
    padding: 12px 24px;
    font-size: 1.1rem;
    background: #fff;
    color: #000;
    text-decoration: none;
    border-radius: 8px;
    transition: transform 0.3s ease, background 0.3s ease;
  }
  
  .cta-button:hover {
    background: #bbb;
    transform: scale(1.05);
  }
  
  
  .nav-icon1 {
    color: white;
    font-size: 28px; /* Adjust icon size */
  }
  
  
  
  /* Content Sections */
  .content {
    padding: 60px 10%;
    text-align: center;
    width: 100%;
  }
  
  .first-content, .second-content {
    background: rgba(255, 255, 255, 0.1);
    padding: 40px;
    border-radius: 10px;
    width: 100%;
    box-shadow: 0px 4px 10px rgba(255, 255, 255, 0.1);
    margin-bottom: 40px;
    transition: transform 0.3s ease-in-out, opacity 0.5s ease;
  }
  
  /* Fade-in Animation */
  .fade-in {
    opacity: 0;
    transform: translateY(20px);
    animation: fadeInUp 1.2s ease-out forwards;
    width: 100%;
  }
  
  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  /* Card Animations */
  .card {
    background: rgba(255, 255, 255, 0.1);
    color: white;
    border-radius: 10px;
    padding: 20px;
    text-align: center;
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  }
  
  .card:hover {
    transform: scale(1.05);
    box-shadow: 0px 5px 15px rgba(255, 255, 255, 0.3);
  }
  
  .links-container {
    margin-top: 20px;
  }
  
  .custom-link {
    display: inline-block;
    margin: 10px;
    padding: 10px 15px;
    background: rgba(255, 255, 255, 0.1);
    color: white;
    border-radius: 5px;
    transition: all 0.3s ease-in-out;
    text-decoration: none;
  }
  
  .custom-link:hover {
    background: rgba(255, 255, 255, 0.3);
  }

  /* Responsive Design */
  @media (max-width: 1024px) {
    .hero-text {
      padding: 15px;
      font-size: 1.5rem;
    }
  
    .type-animation {
      font-size: 1rem;
    }
  
    .sub-text {
      font-size: 1rem;
    }
  
    .cta-button {
      font-size: 1rem;
      padding: 10px 20px;
    }
  }
  
  @media (max-width: 768px) {
    .video-container {
      height: 60vh;
    }
  
    .hero-text {
      font-size: 1.2rem;
    }
  
    .type-animation {
      font-size: 1.2rem;
    }
  
    .content {
      padding: 40px 5%;
    }
  
    .first-content, .second-content {
      padding: 30px;
    }
  }
  
  @media (max-width: 480px) {
    .video-container {
      height: 50vh;
    }
  
    .hero-text {
      font-size: 1rem;
    }
  
    .type-animation {
      font-size: 1rem;
    }
  
    .sub-text {
      font-size: 0.9rem;
    }
  
    .cta-button {
      font-size: 0.9rem;
      padding: 8px 16px;
    }
  
    .content {
      padding: 30px 4%;
    }
  }
  