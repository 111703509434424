/* General Offcanvas Styling - Now with Smooth Slide & Glow Effect */
.offcanvas {
  width: 300px !important; /* Slightly wider for better visibility */
  background: linear-gradient(135deg, #000, #111); /* Subtle gradient */
  color: #fff;
  padding-top: 20px;
  transition: transform 0.5s ease-in-out, box-shadow 0.5s ease-in-out;
  box-shadow: 0 0 20px rgba(255, 255, 255, 0.2);
  border-right: 2px solid rgba(255, 255, 255, 0.2);
}

/* Open Sidebar with a Pop Effect */
.offcanvas.show {
  animation: slideIn 0.4s ease forwards;
}

@keyframes slideIn {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

/* Sidebar Title */
.offcanvas-header {
  background: #fff; /* White header */
  color: #000; /* Black text */
  font-size: 22px;
  font-weight: bold;
  text-align: center;
  padding: 20px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

/* Sidebar Body */
.offbody {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

/* Sidebar Navigation List */
.offbody ul {
  list-style: none;
  padding: 0;
  width: 100%;
  text-align: center;
}

.offbody ul li {
  margin: 10px 0;
  opacity: 0;
  animation: fadeIn 0.4s ease-in-out forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateX(-10px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

/* Sidebar Links */
.offbody ul li a {
  text-decoration: none;
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  padding: 12px 20px;
  display: block;
  width: 90%;
  border-radius: 8px;
  transition: background 0.3s ease, color 0.3s ease, transform 0.2s ease;
  position: relative;
  overflow: hidden;
}

/* Animated Hover Effect */
.offbody ul li a:hover {
  background: rgba(255, 255, 255, 0.15);
  color: #fff;
  transform: translateX(8px);
}

/* Hover Glow Effect */
.offbody ul li a::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 3px;
  bottom: 0;
  left: 0;
  background: white;
  transform: scaleX(0);
  transition: transform 0.3s ease-in-out;
}

.offbody ul li a:hover::after {
  transform: scaleX(1);
}

/* User Profile & Auth Buttons */
.offbody .btn {
  display: block;
  width: 90%;
  padding: 12px;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  color: #000;
  background: #fff;
  border: none;
  margin: 15px 0;
  border-radius: 8px;
  transition: background 0.3s ease, transform 0.2s ease;
  box-shadow: 0 4px 8px rgba(255, 255, 255, 0.3);
}

.offbody .btn:hover {
  background: #ddd;
  transform: translateY(-3px);
  box-shadow: 0 6px 12px rgba(255, 255, 255, 0.5);
}

/* Animated Close Sidebar When Clicking Outside */
.offcanvas-backdrop {
  background: rgba(0, 0, 0, 0.7) !important;
  animation: fadeBackdrop 0.3s ease-in-out;
}

@keyframes fadeBackdrop {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Hamburger Button (Kept As Requested) */
#b {
  background: transparent;
  border: none;
  z-index: 999;
  margin: 0;
  padding: 0;
}

.three-menu {
  font-size: 28px;
  color: #fff;
  cursor: pointer;
  transition: transform 0.3s ease, color 0.3s ease;
}

/* Subtle Animation on Hover */
.three-menu:hover {
  transform: scale(1.2);
  color: #ddd;
}

/* Responsive Design */
@media screen and (max-width: 768px) {
  .offcanvas {
    width: 250px !important;
  }

  .offbody ul li a {
    font-size: 16px;
  }

  .offbody .btn {
    font-size: 14px;
  }
}

@media screen and (max-width: 480px) {
  .offcanvas {
    width: 220px !important;
  }

  .offbody ul li a {
    font-size: 14px;
    padding: 8px;
  }

  .offbody .btn {
    font-size: 12px;
  }
  .three-menu{
    font-size: 20px;
  }
}
