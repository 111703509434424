/* Review Page Container */
.review-page {
    background: linear-gradient(135deg, #000000, #1a1a1a); /* Dark gradient */
    color: #ffffff;
    min-height: 100vh;
    padding: 50px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

/* Page Heading */
.review-page h1 {
    font-size: 32px;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 2px;
    text-shadow: 0px 0px 10px rgba(255, 255, 255, 0.5);
    margin-bottom: 30px;
    animation: fadeIn 1s ease-in-out;
}

/* Fade-in animation */
@keyframes fadeIn {
    from { opacity: 0; transform: translateY(-20px); }
    to { opacity: 1; transform: translateY(0); }
}

/* Message for Unauthenticated Users */
.review-page p {
    font-size: 18px;
    color: #bbbbbb;
    background: rgba(255, 255, 255, 0.1);
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0px 4px 10px rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(5px);
    margin-bottom: 20px;
    animation: fadeIn 1s ease-in-out;
}

/* Review Form & List Container */
.review-form, .review-list-container {
    width: 90%;
    max-width: 750px;
    margin: 20px auto;
}

/* Responsive Design */
@media (max-width: 1024px) {
    .review-page h1 {
        font-size: 28px;
    }

    .review-page p {
        font-size: 16px;
    }
}

@media (max-width: 768px) {
    .review-page {
        padding: 40px 15px;
    }

    .review-page h1 {
        font-size: 26px;
    }

    .review-form, .review-list-container {
        width: 95%;
    }
}

@media (max-width: 480px) {
    .review-page {
        padding: 30px 10px;
    }

    .review-page h1 {
        font-size: 24px;
    }

    .review-page p {
        font-size: 14px;
        padding: 12px;
    }

    .review-form, .review-list-container {
        width: 98%;
    }
}
