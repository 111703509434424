/* General Footer Styling */
.footer {
    background: linear-gradient(135deg, #1e1e1e, #3a3a3a);
    color: white;
    padding: 40px 20px;
    text-align: center;
    font-family: "Poppins", sans-serif;
  }
  
  .footer-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    max-width: 1200px;
    margin: auto;
  }
  
  /* Left Section */
  .footer-left p {
    font-size: 14px;
    opacity: 0.8;
  }
  
  /* Quick Links */
  .footer-links h6,
  .footer-socials h6 {
    font-size: 16px;
    text-transform: uppercase;
    margin-bottom: 10px;
    position: relative;
  }
  
  .footer-links ul,
  .footer-socials ul {
    list-style: none;
    padding: 0;
  }
  
  .footer-links li,
  .footer-socials li {
    margin: 8px 0;
    transition: transform 0.3s ease-in-out;
  }
  
  .footer-links li:hover,
  .footer-socials li:hover {
    transform: translateY(-3px);
  }
  
  .footer-links a,
  .footer-socials a {
    text-decoration: none;
    color: white;
    font-size: 14px;
    transition: color 0.3s ease-in-out;
  }
  
  .footer-links a:hover,
  .footer-socials a:hover {
    color: #ffbb00;
  }
  
  /* Social Icons */
  .footer-socials ul {
    display: flex;
    justify-content: center;
    gap: 12px;
  }
  
  .footer-socials li a {
    font-size: 20px;
    transition: transform 0.3s ease-in-out, color 0.3s;
  }
  
  .footer-socials li a:hover {
    transform: scale(1.2);
    color: #ffbb00;
  }
  
  /* Footer Bottom */
  .footer-bottom {
    margin-top: 20px;
    font-size: 14px;
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    padding-top: 15px;
  }
  
  .developer-link {
    margin-left: 5px;
    color: #ffbb00;
    text-decoration: none;
    font-weight: bold;
    transition: color 0.3s ease-in-out;
  }
  
  .developer-link:hover {
    color: #ff5500;
  }
  
  /* Responsive Styles */
  @media (max-width: 768px) {
    .footer-content {
      flex-direction: column;
      text-align: center;
    }
  
    .footer-links ul,
    .footer-socials ul {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      gap: 10px;
    }
  
    .footer-links li,
    .footer-socials li {
      margin: 5px;
    }
  }
  