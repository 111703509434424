/* General Styles */
.main_div {
   display: flex;
   align-items: center;
   justify-content: space-between;
   background: black;
   padding: 10px 20px;
   width: 100%;
 }
 
 /* Navbar Container */
 .navbar {
   display: flex;
   align-items: center;
   justify-content: space-between;
   width: 100%;
   max-width: 1200px;
   margin: auto;
 }
 
 /* Website Name */
 .main-heading {
   font-size: 24px;
   font-weight: bold;
   color: white;
   text-align: center;
 }
 
 /* User Profile Button */
 .user-login {
   display: flex;
   align-items: center;
   background: none;
   border: none;
   cursor: pointer;
 }
 
 /* Profile Image */
 .nav-profile-pic {
   width: 40px;
   height: 40px;
   border-radius: 50%;
   object-fit: cover;
   border: 2px solid white;
 }
 
 /* Default User Icon */
 .default-user-icon {
   font-size: 40px;
   color: white;
 }
 
 /* Username */
 .username {
   color: white;
   font-size: 18px;
   margin-left: 10px;
   white-space: nowrap; /* Prevents text from breaking */
 }
 
 /* Navigation Icons */
 .nav-icons {
   display: flex;
   gap: 100px;
 }
 
 .icon-btn {
   background: none;
   border: none;
   cursor: pointer;
   color: white;
   font-size: 22px;
   transition: 0.3s ease;
 }
 
 .icon-btn:hover {
   color: grey;
 }
 
 /* Notification and Upload Icons */
 .nav-icon {
   font-size: 24px;
 }
 .sidebar {
   background-color: #fff;  /* White background */
   color: #000;  /* Black text for contrast */
   box-shadow: 2px 0 10px rgba(0, 0, 0, 0.1);
   transition: all 0.3s ease;
 }
 
 /* Responsive Design */
 
 /* For Tablets (768px - 1024px) */
 @media screen and (max-width: 1024px) {
   .main-heading {
     font-size: 20px;
   }
 
   .nav-profile-pic {
     width: 35px;
     height: 35px;
   }
 
   .default-user-icon {
     font-size: 35px;
   }
 
   .username {
     font-size: 16px;
   }
 
   .nav-icon {
     font-size: 20px;
   }
 }
 
 /* For Mobile Devices (Below 768px) */
 @media screen and (max-width: 768px) {
   .navbar {
     flex-direction: row;
     justify-content: space-between;
     padding: 10px;
   }
 
   .main-heading {
     font-size: 18px;
   }
 
   .user-login {
     flex-direction: column;
     align-items: center;
   }
 
   .nav-profile-pic,
   .default-user-icon {
     width: 30px;
     height: 30px;
   }
 
   .username {
     font-size: 14px;
   }
 
   .nav-icons {
     gap:25 px;
   }
 
   .icon-btn {
     font-size: 18px;
   }
 }
 
 /* Small Mobile Screens (Below 480px) */
 @media screen and (max-width: 480px) {
   .navbar {
     flex-direction: row;
     justify-content: space-between;
     padding: 8px;
   }
 
   .main-heading {
     font-size: 16px;
   }
 
   .username {
     display: none; /* Hide username on very small screens */
   }
 
   .nav-profile-pic,
   .default-user-icon {
     width: 28px;
     height: 28px;
   }
 
   .nav-icons {
     gap: 3em ;
   }
 
   .icon-btn {
     font-size: 16px;
   }
 }
 