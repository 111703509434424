/* Profile Page Styling */
.profile-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #000;
  color: #fff;
  font-family: 'Poppins', sans-serif;
  padding: 20px;
}

/* Profile Card */
.profile-card {
  background: #111;
  padding: 30px;
  border-radius: 12px;
  box-shadow: 0 4px 10px rgba(255, 255, 255, 0.2);
  text-align: center;
  width: 50%;
  max-width: 500px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.profile-card:hover {
  transform: scale(1.03);
  box-shadow: 0 6px 15px rgba(255, 255, 255, 0.3);
}

/* Profile Image */
.profile-pic {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  object-fit: cover;
  border: 4px solid #fff;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.profile-pic:hover {
  transform: rotate(10deg) scale(1.1);
}

/* Profile Name */
.h2-profile {
  margin-top: 15px;
  font-size: 22px;
  font-weight: 600;
}

/* Profile Data */
.profile-data {
  display: flex;
  align-items: center;
  gap: 1em; /* Space between label and input */
  justify-content: space-between;
  margin-top: 10px;
  flex-wrap: wrap;
}

/* Labels */
.profile-data label {
  font-weight: bold;
  white-space: nowrap; /* Prevents label from wrapping */
}

/* Profile Paragraph */
.p-profile {
  font-size: 14px;
  color: #ccc;
  background: #f0f0f0;
  border-radius: 6px;
  flex-grow: 1;
  padding: 5px;
  text-align: center;
}

/* Input Fields */
.input-profile {
  width: 100%;
  max-width: 250px;
  padding: 5px;
  margin-top: 6px;
  border: 1px solid #fff;
  background: transparent;
  color: #fff;
  border-radius: 6px;
  outline: none;
  text-align: center;
  transition: all 0.3s ease;
}

.inf {
  flex-grow: 0.5;
}

.input-profile:focus {
  border-color: #1dbf73;
  background: rgba(255, 255, 255, 0.1);
}

/* Save Button */
.btn-profile {
  margin-top: 15px;
  padding: 10px 20px;
  border: none;
  border-radius: 6px;
  background: #1dbf73;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  transition: background 0.3s ease, transform 0.2s ease;
}

.btn-profile:hover {
  background: #17a362;
  transform: translateY(-2px);
}

/* Responsive Styles */

/* For Tablets (768px - 1024px) */
@media screen and (max-width: 1024px) {
  .profile-card {
    width: 70%;
    padding: 20px;
  }

  .profile-pic {
    width: 100px;
    height: 100px;
  }

  .h2-profile {
    font-size: 20px;
  }

  .profile-data {
    flex-direction: column;
    align-items: flex-start;
  }

  .input-profile {
    max-width: 100%;
    width: 100%;
  }

  .btn-profile {
    width: 100%;
  }
}

/* For Mobile Devices (Below 768px) */
@media screen and (max-width: 768px) {
  .profile-card {
    width: 85%;
    padding: 20px;
  }

  .profile-pic {
    width: 90px;
    height: 90px;
  }

  .h2-profile {
    font-size: 18px;
  }

  .profile-data {
    flex-direction: column;
    align-items: flex-start;
  }

  .p-profile {
    font-size: 13px;
  }

  .input-profile {
    width: 100%;
  }

  .btn-profile {
    width: 100%;
    font-size: 13px;
    padding: 8px 15px;
  }
}

/* Small Mobile Screens (Below 480px) */
@media screen and (max-width: 480px) {
  .profile-card {
    width: 95%;
    padding: 15px;
  }

  .profile-pic {
    width: 80px;
    height: 80px;
  }

  .h2-profile {
    font-size: 16px;
  }

  .p-profile {
    font-size: 12px;
  }

  .input-profile {
    font-size: 12px;
  }

  .btn-profile {
    font-size: 12px;
    padding: 7px 12px;
  }
}
