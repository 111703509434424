/* 🎟️ Slideshow Container */
.slideshow {
  text-align: center;
  padding: 25px;
  background: #0d0d0d; /* True Black */
  border-radius: 12px;
  box-shadow: 0px 4px 10px rgba(255, 255, 255, 0.1);
  height: fit-content;
}

/* 👤 User Greeting */
.user-greeting {
  color: #fff;
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 15px;
}

/* 🏷️ Title */
.coupon-title {
  font-size: 1.8rem;
  font-weight: bold;
  color: #fff;
  margin-bottom: 25px;
  text-transform: uppercase;
  letter-spacing: 1px;
  animation: fadeIn 1s ease-in-out;
}

/* 🎨 Coupon Wrapper */
.coupons-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 25px; /* Increased spacing */
  padding: 20px;
  height: fit-content;
}

/* 🎟️ Coupon Card */
.coupon {
  width: 300px; /* Slightly bigger for better readability */
  padding: 22px;
  background: linear-gradient(145deg, #1a1a1a, #222);
  border-radius: 12px;
  text-align: center;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  border: 2px solid #fff;
  transition: transform 0.3s, box-shadow 0.3s, background 0.3s;
  box-shadow: 0 6px 15px rgba(255, 255, 255, 0.15);
  display: flex;
  flex-direction: column;
  gap: 12px; /* Ensures proper spacing */
  height: fit-content;
}

/* ✨ Hover Effect */
.coupon:hover {
  transform: translateY(-5px);
  background: #292929;
  box-shadow: 0px 0px 15px rgba(255, 255, 255, 0.2);
}

/* 🔠 Coupon Code */
.code {
  font-size: 1.3rem;
  font-weight: bold;
  color: #fff;
  padding-bottom: 0px;
  margin-bottom: 5px;
  text-transform: uppercase;
  border-bottom: 2px dashed #aaa;
  letter-spacing: 1px;
}

/* 📝 Coupon Description */
.description {
  font-size: 0.95rem;
  color: #ddd;
  margin:0 0 -10px 0; /* Increased spacing */
  font-style: italic;
  text-align: center;
  line-height: 1.5;
  background: rgba(255, 255, 255, 0);
  border-radius: 6px;
  word-wrap: break-word;
  min-height: 50px;
  padding: 10px;
}

/* 💰 Discount */
.discount {
  font-size: 1.1rem;
  font-weight: bold;
  color: #f1f0ed;
  margin-top: 10px;
  padding: 10px;
  border: 2px solid #f9ebb4d3;
  border-radius: 8px;
  display: inline-block;
  background: rgba(255, 204, 0, 0.1);
  min-width: 60%;
}

/* 🎯 Limited Users */
.limited-users {
  font-size: 0.95rem;
  color: #aaa;
  font-weight: 500;
  margin-top: 15px; /* Increased spacing to avoid overlap */
}

/* 🔄 Improved Layout */
.coupon-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
}

/* 📱 Responsive Styles */
@media (max-width: 768px) { /* Tablets */
  .coupons-wrapper {
    flex-direction: column;
    align-items: center;
  }

  .coupon {
    width: 85%;
  }
}

@media (max-width: 480px) { /* Smartphones */
  .coupons-wrapper {
    flex-direction: column;
    align-items: center;
    gap: 15px;
  }

  .coupon {
    width: 95%;
    padding: 20px;
  }

  .code {
    font-size: 1.2rem;
  }

  .description {
    font-size: 0.9rem;
    min-height: 55px;
  }

  .discount {
    font-size: 1rem;
    padding: 8px;
  }

  .limited-users {
    font-size: 0.9rem;
  }
}

/* 🎭 Animations */
@keyframes fadeIn {
  from { opacity: 0; transform: translateY(-10px); }
  to { opacity: 1; transform: translateY(0); }
}
