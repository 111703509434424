/* General Styling */
.about-container {
  width: 100%;
  padding: 50px 20px;
  background-color: #000;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

/* About Heading */
.about-heading {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 20px;
  text-transform: uppercase;
  letter-spacing: 2px;
}

/* About Text */
.about-text {
  font-size: 1.1rem;
  max-width: 900px;
  line-height: 1.6;
  color: #ccc;
  text-align: center;
  margin-bottom: 30px;
}

/* Team Section */
.team-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  width: 90%;
  max-width: 1200px;
  justify-content: center;
}

/* Team Cards */
.team-card {
  background-color: #111;
  border-radius: 10px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  text-align: center;
}

.team-card:hover {
  transform: translateY(-5px);
  box-shadow: 0px 4px 15px rgba(255, 255, 255, 0.3);
}

/* Team Images */
.team-image {
  width: 100%;
  height: auto; /* Maintains aspect ratio */
  max-height: 200px; /* Prevents overly large images */
  object-fit: contain; /* Ensures full image visibility */
  border-radius: 20px; /* Adds a curved border */
  margin: 0;
}

/* Name & Role */
.team-name {
  font-size: 1.4rem;
  font-weight: bold;
  margin-top: 10px;
}

.team-role {
  font-size: 1rem;
  color: #aaa;
  margin: 5px 0;
}

/* Social Icon */
.social-icon {
  font-size: 1.5rem;
  cursor: pointer;
  color: #fff;
  margin-top: 10px;
  transition: color 0.3s ease-in-out;
}

.social-icon:hover {
  color: #aaa;
}

/* Dropdown for Social Links */
.social-links {
  position: absolute;
  top: 50px;
  background: rgba(0, 0, 0, 0.9);
  border-radius: 8px;
  padding: 10px;
  width: 160px;
  display: none;
  flex-direction: column;
  gap: 8px;
  z-index: 10;
  text-align: left;
}

.team-card:hover .social-links {
  display: flex;
}

.social-links a {
  color: #fff;
  text-decoration: none;
  font-size: 0.9rem;
  transition: color 0.3s;
}

.social-links a:hover {
  color: #aaa;
}

/* Responsive Layout */
@media screen and (max-width: 1024px) {
  .team-container {
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
      gap: 15px;
  }
}

@media screen and (max-width: 768px) {
  .about-heading {
      font-size: 2rem;
  }

  .about-text {
      font-size: 1rem;
      padding: 0 15px;
  }

  .team-container {
      grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
      gap: 15px;
      width: 95%;
  }

  .team-card {
      padding: 15px;
  }

  .team-image {
      height: 160px;
  }

  .team-name {
      font-size: 1.3rem;
  }

  .team-role {
      font-size: 0.9rem;
  }
}

@media screen and (max-width: 480px) {
  .about-heading {
      font-size: 1.8rem;
  }

  .about-text {
      font-size: 0.95rem;
  }

  .team-container {
      grid-template-columns: 1fr;
      width: 100%;
  }

  .team-card {
      width: 90%;
      min-height: auto;
  }

  .team-image {
      height: 140px;
  }

  .team-name {
      font-size: 1.2rem;
  }
}
