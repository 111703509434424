/* Prevent layout shifting */
.appointment-page {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 100px); /* Adjust height without affecting footer */
  padding: 50px 15px;
  background-color: #000;
  color: #fff;
}

/* Wrapper */
.appointment-container {
  width: 90%;
  max-width: 700px;
  background-color: #111;
  padding: 25px;
  border-radius: 12px;
  box-shadow: 0 5px 15px rgba(255, 255, 255, 0.2);
  animation: fadeIn 1s ease-in-out;
  text-align: center;
}

/* Title */
.appointment-title {
  font-size: 24px;
  margin-bottom: 15px;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #fff;
}

/* Form Grid */
.form-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;
  margin-bottom: 15px;
}

/* Labels */
.appointment-label {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  color: #ddd;
  font-weight: 500;
}

/* Inputs & Select */
.appointment-input,
.appointment-select {
  width: 100%;
  padding: 12px;
  background: #222;
  color: #fff;
  border: 2px solid #333;
  border-radius: 8px;
  font-size: 14px;
  transition: all 0.3s ease;
}

.appointment-input:focus,
.appointment-select:focus {
  border-color: #fff;
  outline: none;
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.3);
}

/* Textarea */
.appointment-textarea {
  width: 100%;
  min-height: 100px;
  resize: none;
  padding: 12px;
  background: #222;
  border: 2px solid #333;
  color: #fff;
  border-radius: 8px;
}

/* Submit Button */
.appointment-button {
  width: 100%;
  padding: 12px;
  background: linear-gradient(45deg, #fff, #777);
  color: #000;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-top: 15px;
}

.appointment-button:hover {
  background: linear-gradient(45deg, #ddd, #444);
  transform: scale(1.05);
}

/* Responsive */
@media (max-width: 600px) {
  .form-grid {
    grid-template-columns: 1fr;
  }
}
