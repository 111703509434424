/* Card Container - 2×2 Grid Layout */
.card_container {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* 2 columns */
    grid-template-rows: repeat(2, auto); /* 2 rows */
    gap: 20px; /* Space between cards */
    padding: 40px;
    justify-content: center;
    align-items: center;
  }
  
  /* Bootstrap Card Styling */
  .card {
    background: linear-gradient(145deg, #111, #222); /* Black gradient */
    color: white;
    border-radius: 12px;
    padding: 20px;
    text-align: center;
    transition: transform 0.4s ease-in-out, box-shadow 0.4s ease-in-out;
    position: relative;
    overflow: hidden;
    box-shadow: 0px 5px 15px rgba(255, 255, 255, 0.1);
  }
  
  /* Hover Effects */
  .card:hover {
    transform: scale(1.05);
    box-shadow: 0px 10px 25px rgba(255, 255, 255, 0.3);
  }
  
  /* Card Text Styling */
  .card-title {
    font-size: 1.2em;
    font-weight: bold;
  }
  
  .card-text {
    font-size: 0.9em;
  }
  
  /* Link Styling */
  .card-link {
    color: #ffffff;
    font-weight: bold;
    text-decoration: none;
    transition: color 0.3s ease-in-out;
  }
  
  .card-link:hover {
    color: #ffcc00; /* Gold highlight effect */
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .card_container {
      grid-template-columns: 1fr; /* Switch to single column on smaller screens */
      grid-template-rows: auto;
      gap: 15px;
      padding: 20px;
    }
  }
  